import * as React from 'react';
import { useEffect, useState } from 'react'
import TerminalStatusCard from '../components/TerminalStatusCard';
import MainLayout from '../layouts/MainLayout';
import { Grid, GridSize, Box, Select, MenuItem, Button, SelectChangeEvent } from '@mui/material';
import getTerminalStatusList, { Response as StatusResponse } from '../api-clients/actions/getTerminalStatusList';
import getOrganizationChildren, { Organization, Response as OrganizationChildrenResponse } from '../api-clients/actions/getOrganizationChildren';
import { useNotificationAlert } from '../components/NotificationAlert';

interface SelectorProps {
  isFetchButtonDisabled: boolean
  onFetch: (organizationId: number) => void
  onError: (message: string) => void
}

function OrganizationSelector(props: SelectorProps) {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>(0)
  const [organizationsList, setOrganizationsList] = useState<Organization[][]>([])

  // 画面に表示されているSELECT要素の値を保持する
  // 0 ~ N で、0番目は、画面上では一番左のSELECT要素の値(value)になり、N番目は、画面上は一番右のSELECT要素の値になる
  const [selectElementValueList, setSelectElementValueList] = useState<number[]>([])
  useEffect(() => {
    // 子組織の一覧を取得
    getOrganizationChildren()
      .then((response: OrganizationChildrenResponse) => {
        setOrganizationsList([response.organizations])
      })
      .catch((e: Error) => {
        props.onError(e.message)
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // セレクトボックスの変更イベント
  const handleSelectChange = (e: SelectChangeEvent, index: number) => {
    const selectedValue = parseInt(e.target.value);

    let tmp = selectElementValueList.slice(0, index)
    tmp[index] = selectedValue
    setSelectElementValueList([...tmp])

    setSelectedOrganizationId(selectedValue)

    const list = [...(organizationsList.slice(0, index + 1))];
    setOrganizationsList([...list])

    // "ー 未選択 ー"でない時だけ、子組織を取得する
    if (selectedValue > 0) {
      // 子組織の一覧を取得
      getOrganizationChildren(selectedValue)
        .then((response: OrganizationChildrenResponse) => {
          setOrganizationsList([...list, response.organizations])
        }).catch((e: Error) => {
          props.onError(e.message)
        })
    }
  }

  // 取得するボタンを押された時
  const handleButtonClick = () => {
    props.onFetch(findOrganizationId())
  }

  // 適切な子組織のIDを返す
  const findOrganizationId = () => {
    if (selectedOrganizationId > 0) {
      return selectedOrganizationId
    }

    // "ー 未選択 ー"を表す値を除去する
    // 未選択ではない、1番階層の深い(画面的には1番右にあるSELECT要素)選択中の値を取得したい
    const last = [...selectElementValueList].reverse().filter((v) => {
      return v !== 0
    });

    return last.length > 0 ? last[0] : 0;
  }

  return (
      <Grid container sx={{m:1}}>
        {
          organizationsList.filter((v) => v.length > 0).map((organizations, index) => {
            return (
              <React.Fragment key={index}>
                <Select
                  value={`${selectElementValueList[index] || 0}`}
                  onChange={(e) => {
                    handleSelectChange(e, index)
                  }}
                >
                  <MenuItem value={0}>ー 未選択 ー</MenuItem>
                {
                  organizations.map((org, index) => {
                    return <MenuItem value={org.id} key={index}>{org.name}</MenuItem>
                  })
                }
                </Select>
                <Box sx={{ mt: 1, mr: 2 }} />
            </React.Fragment>
            )
          })
        }
        {
          // 子組織がない時も、再取得を明示的に行えるようにボタンは表示する
          <Button disabled={props.isFetchButtonDisabled} variant="contained" onClick={handleButtonClick}>取得する</Button>
        }
      </Grid>
    )
}


export function Content() {
  const [xs, sm, md, lg, xl] = [12, 12, 6, 4, 4] as GridSize[];
  const [terminalStatusList, setTerminalStatusList] = useState<StatusResponse[]>([])
  const [isFetchBtnDisabled, setIsFetchBtnDisabled] = useState<boolean>(false)
  const { pushNotificationAlert } = useNotificationAlert()

  useEffect(() => {
    handleFetch();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onError = (message: string) => {
    pushNotificationAlert({ severity: 'error', title: message })
  }

  // 取得するボタンを押された時に呼ばれる
  const handleFetch = (orgId?: number) => {
    setIsFetchBtnDisabled(true)
    getTerminalStatusList(orgId)
      .then((response: StatusResponse[]) => {
        setTerminalStatusList(response);
      })
      .catch((e: Error) => {
        onError(e.message)
        console.error(e)
      }).finally(() => {
        setIsFetchBtnDisabled(false)
      })
  }

  return (
    <React.Fragment>
      <OrganizationSelector onError={onError} onFetch={handleFetch} isFetchButtonDisabled={isFetchBtnDisabled} />
      <Grid container spacing={2}>
        {
          terminalStatusList.map((data, index) => {
            return (
              <Grid key={index} container item justifyContent="center" xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
                <TerminalStatusCard
                  title={data.title}
                  subheader={data.info.createdAt}
                  message={data.info.message}
                  background={convertErrorLevel(data.info.errorLevel)}
                  image={data.info.screenshotUri}/>
              </Grid>
            )
          })
        }
      </Grid>
    </React.Fragment>
  );
}

function convertErrorLevel(level: string): 'error'|'normal' {
  switch(level) {
    case 'Error':
    case 'Critical':
    case 'Alert':
    case 'Emergency':
      return 'error';
    default:
      return 'normal';
  }
}

export default function TerminalStatusList() {
  return <MainLayout Content={<Content />} title="端末ステータス" />
}
