import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { makeStyles, createStyles } from '@material-ui/styles';
import { red } from "@material-ui/core/colors"

// import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';

interface StyleTypeHint {
  mediaWrapper: any,
  img: any,
  errorHeader: any,
}

const useStyles = makeStyles(() => createStyles({
  mediaWrapper: {
    width: '100%',
    height: '200px',
    minHeight: '200px',
    maxHeight: '200px',
    '& img': {
      width: 'auto !important',
      maxWidth: '100%',
      maxHeight: '200px',
    }
  },
  errorHeader: {
    backgroundColor: red[600],
    color: '#EEE',
    '& .MuiCardHeader-subheader': {
      color: 'inherit',
    }
  },
}) as StyleTypeHint );


interface Props {
  title: string
  subheader: string
  message: string
  image: string
  background?: 'normal' | 'error'
}


export default function TerminalStatusCard(props: Props){
  const classes = useStyles();
  const headerClassName = (props.background) === 'error' ? classes.errorHeader : '';

  return (
    <Card sx={{ width: '100%' }} raised={true}>
      <CardHeader
        className={headerClassName}
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={props.title}
        subheader={props.subheader}
      />
      <Grid container justifyContent="center" alignItems="center" component="div" className={classes.mediaWrapper}>
        <CardMedia
          className={classes.img}
          component="img"
          width="auto"
          image={props.image}
          alt="Paella dish"
        />
      </Grid>
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {props.message}
        </Typography>
      </CardContent>
    </Card>
  );
}
