import * as React from 'react';
import Title from '../components/Title';
import MainLayout from '../layouts/MainLayout';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import { Grid, Box, Button } from '@mui/material';
import {ja} from 'date-fns/esm/locale'
import { FormEvent } from 'react';
import downloadCustomerLog from '../api-clients/actions/downloadCustomerLogs';
import { useNotificationAlert } from '../components/NotificationAlert';
// import lastDayOfMonth from 'date-fns/lastDayOfMonth'

function Content(): JSX.Element {
  const [fromDate, setFromDate] = React.useState<Date|null>(firstDayOfMonth())

  const [toDate, setToDate] = React.useState<Date|null>(new Date())

  const [disabledSubmit, setDisabledSubmit] = React.useState<boolean>(false);

  const {pushNotificationAlert, clearNotificationAlert} = useNotificationAlert()

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    clearNotificationAlert()
    setDisabledSubmit(true);

    const formData = new FormData(event.currentTarget);
    formData.append("encoding", 'utf8')
    
    // ダウンロード処理を開始する
    downloadCustomerLog(formData, (text) => {
      pushNotificationAlert({
        title: text,
        severity: "error",
      })
    }).finally(() => {
      setDisabledSubmit(false);
    });
  }

  return (
    <React.Fragment>
      <Title>購買者ログのダウンロード</Title>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{m: 2}}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ja}>
          <Grid container justifyContent={"center"}>
            <Grid item container xs={12} justifyContent={"center"}>
              <DatePicker
                disableFuture
                label="集計開始日"
                value={fromDate}
                minDate={new Date('2021-01-01')}
                onChange={(selectedFromDate) => {
                  setFromDate(selectedFromDate)
                }}
                renderInput={(params) => <TextField {...params} name="fromDate" />}
                mask="____/__/__"
                inputFormat="yyyy/MM/dd"
              />
              <Box sx={{ m:5 }} />
              <DatePicker
                disableFuture
                label="集計終了日"
                value={toDate}
                minDate={new Date('2021-01-01')}
                onChange={(selectedToDate) => {
                  setToDate(selectedToDate);
                }}
                renderInput={(params) => <TextField {...params} name="toDate" />}
                mask="____/__/__"
                inputFormat="yyyy/MM/dd"
              />
            </Grid>
            <Grid item container xs={12} justifyContent={"center"}>
              <Grid item xs={6}>
                <Button disabled={disabledSubmit} type="submit" variant="contained" fullWidth>ダウンロードする</Button>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>
    </React.Fragment>
  )
}

function firstDayOfMonth(): Date {
  const d = new Date()
  return new Date(d.getFullYear(), d.getMonth(), 1);
}

export default function CustomerLog() {
  return <MainLayout Content={<Content />} title="購買者ログ" />
}
