import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect, RouteProps } from "react-router-dom"
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import CustomerLog from './pages/CustomerLog'
import { Provider as AppContextProvider, useAppContext } from './AppContext'
import PageNotFound from "./pages/http-errors/PageNotFound"

const App: React.FC = () => {
  return (
    <AppContextProvider>
      <Router>
        <Switch>
          {/* 未承認ユーザだけがアクセスできるルート */}
          <UnauthenticatedRoute path="/login" component={Login} exact />

          {/* 承認済みユーザだけがアクセスできるルート */}
          <AuthenticatedRoute path="/" component={Dashboard} exact />
          <AuthenticatedRoute path="/logs/seventeen/customer" component={CustomerLog} exact />

          {/* 404 ページ */}
          <Route path="*" component={PageNotFound} />
        </Switch>
      </Router>
    </AppContextProvider>
  )
}

export default App


/** 未承認ユーザだけがアクセスできる */
const UnauthenticatedRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useAppContext();
  if (isLoggedIn) {
    return <Redirect to="/"/>
  }
  return <Route {...props} />
}


/** 承認済みユーザだけがアクセスできる */
const AuthenticatedRoute: React.FC<RouteProps> = ({...props}) => {
  const {isLoggedIn} = useAppContext();
  if (isLoggedIn) {
    return <Route {...props} />
  }
  return <Redirect to="/login"/>
}