import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useHistory } from 'react-router';
import Copyright from '../components/Copyright'
import { useAppContext } from '../AppContext';
import { FormErrorMessage } from '../components/FormErrorMessage';
import login from '../api-clients/actions/login'

const theme = createTheme();

export default function Login() {
  const [isDisabledSubmit, setDisabledSubmit] = React.useState(false)

  const [errorText, setErrorText] = React.useState('')

  const history = useHistory();

  const {setLoggedIn} = useAppContext()

  const handleSubmit = (event: any) => {
    event.preventDefault();
    
    // エラーテキストをクリアする
    setErrorText('')

    // サブミットボタンを無効化する
    setDisabledSubmit(true)
    
    const data = new FormData(event.currentTarget);

    login(data).then(() => {
      // ログイン状態にする
      setLoggedIn(true)

      // TODO: ユーザ情報(権限等)を、セットする
      // setUserInfo(...)

      // ダッシュボードに遷移する
      history.replace('/');
    }).catch((e: any) => {
      // エラーメッセージをセット
      setErrorText((e as Error).message);

      // サブミットボタンを有効化する
      setDisabledSubmit(false)
    })
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" gutterBottom>
            LOGIN
          </Typography>

          { /* エラーメッセージを表示するUI */ }
          <FormErrorMessage message={errorText}/>

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="login-id"
              label="ログインID"
              name="login_id"
              autoComplete="login-id"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="パスワード"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isDisabledSubmit}
            > ログイン
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}