import { AxiosResponse } from 'axios';
import { createAxios, defaultCatch } from '../axiosUtil'
import PermanentDataStore from '../../supports/PermanentDataStore'
import CmsHttpError from '../../errors/CmsHttpError'

interface LoginResponse {
    token: string;
}

export default function login(data: FormData): Promise<any> {
    return createAxios()
    .post<LoginResponse>('/login', data)
    .then((response: AxiosResponse<LoginResponse, any>) => {
        // トークンを保存する
        const store = PermanentDataStore.load();
        store.updateToken(response.data.token);
        store.sync();

        return Promise.resolve();
    }).catch<string>((reason: any) => {
        let message = '';
        let [status, defaultMessage] = defaultCatch(reason, {
            401: () => {
                message = reason.response?.data?.message ?? "";
            }
        })

        // 401以外の時は、デフォルトメッセージを利用する
        if (401 !== status) {
            message = defaultMessage;
        }

        return Promise.reject(CmsHttpError.factory([status, message]));
    })
}