import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import NavBar from '../components/NavBar';
import DrawerMenu from '../components/DrawerMenu';
import Copyright from '../components/Copyright';
import NotificationAlert, { NotificationAlertProvider } from '../components/NotificationAlert'

const mdTheme = createTheme();

interface MainLayoutProps {
  Content: JSX.Element;
  title: string;
}

export default function MainLayout({ Content, title }: MainLayoutProps) {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <NavBar isDrawerOpen={open} toggleDrawer={toggleDrawer} title={title} />
        <DrawerMenu isDrawerOpen={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <NotificationAlertProvider>
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <NotificationAlert />
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    { Content }
                  </Paper>
                </Grid>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </NotificationAlertProvider>
        </Box>
      </Box>
    </ThemeProvider>
  );
}