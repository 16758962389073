import { AxiosResponse } from 'axios';
import CmsHttpError from '../../errors/CmsHttpError';
import { createAxios, defaultCatch } from '../axiosUtil'

export interface UserInfo {
    name: string
    menu: string[]
}

export const defaultUserInfo: () => UserInfo = () => ({
    name: "",
    menu: [],
})

export default function getUserInfo(): Promise<any> {
    return createAxios().withToken()
    .get<UserInfo>('/users/me')
    .then((response: AxiosResponse<UserInfo, any>) => {
        return Promise.resolve(response.data);
    }).catch<string>((reason: any) => {
        const error = CmsHttpError.factory(defaultCatch(reason));
        return Promise.reject(error);
    })
}