import { AxiosResponse } from 'axios';
import CmsHttpError from '../../errors/CmsHttpError';
import { createAxios, defaultCatch } from '../axiosUtil'

export interface Organization {
    id: string
    name: string
}

export interface Response {
    organizations: Organization[]
    total: number
}

export default function getOrganizationChildren(organizationId?: number|string): Promise<any> {
    return createAxios().withToken()
        .get<Response[]>(`/organization-children/${organizationId || ''}`)
        .then((response: AxiosResponse<Response[], any>) => {
            return Promise.resolve(response.data);
        }).catch<string>((reason: any) => {
            const error = CmsHttpError.factory(defaultCatch(reason));
            return Promise.reject(error);
        })
}