import IconButton from '@mui/material/IconButton';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useAppContext } from '../AppContext';
import logout from '../api-clients/actions/logout'

export default function LogoutButton() {
  const history = useHistory();
  const { setLoggedIn } = useAppContext();

  const handleLogout = () => {
    // ログアウトAPIをコール
    logout().catch((e: any) => {
      console.error(e)
    })

    // ログアウト状態にする
    setLoggedIn(false)

    // ログイン画面に遷移
    history.push('/login')
  }

  return (
    <IconButton color="inherit" onClick={handleLogout}>
      <LogoutIcon />
    </IconButton>
  );
}