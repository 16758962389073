interface Config {
  copyright: { url: string, text: string }
  readonly baseEndpoint: string
}

const config: Config = {
  copyright: {
    url: "https://ybs-inc.biz/",
    text: "YBS",
  },
  baseEndpoint: "https://cms-dev.ybs-inc.biz/api/",
}

export default config
