import React from "react"
import PermanentDataStore from "./supports/PermanentDataStore"
import { setDefaultAction } from './api-clients/axiosUtil'
import getUserInfo, { UserInfo, defaultUserInfo } from "./api-clients/actions/getUserInfo"
import OverlaidLoading from './components/OverlaidLoading'

// Dialog
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CmsHttpError from "./errors/CmsHttpError"


type AppContextInterface = {
  isLoggedIn: boolean,
  setLoggedIn: (_: boolean) => void,
  userInfo: UserInfo,
  clearUserInfo: () => void
  setUserInfo: (info :UserInfo) => void

  ///// ローディングUI の表示・非表示 /////
  isOverlaidLoading: boolean
  setIsOverlaidLoading: (_: boolean) => void
}

type ReloadedDialogProps = {
  isOpen: boolean
  message: string
  onClose: () => void,
}

export const AppContext = React.createContext<AppContextInterface>({} as AppContextInterface);
export const useAppContext = () => React.useContext<AppContextInterface>(AppContext)

export const Provider: React.FC = ({ children }) => {
  // ログイン状態を管理
  const [isLoggedIn, setLoggedIn] = React.useState<boolean>(
    PermanentDataStore.load().hasToken())

  // ユーザ情報を定義
  const [userInfo, setUserInfo] = React.useState<UserInfo>(defaultUserInfo());
  const clearUserInfo = () => {
    setUserInfo(defaultUserInfo());
  }

  // ローディング画面の表示・非表示API
  const [isOverlaidLoading, setIsOverlaidLoading] = React.useState<boolean>(true)

  /// 強制的にリロードを行わせるための実装
  const [reloadedDialogState, setReloadedDialogState] = React.useState<ReloadedDialogProps>({
    isOpen: false,
    message: "",
    onClose: () => {
      // setReloadedDialogState({...reloadedDialogState})
      (window as Window).location.reload();
    }
  });

  /** 401 レスポンスを受けたときの共通処理をセット */
  setDefaultAction(401, () => { setLoggedIn(false) });

  // ユーザの登録情報を取得する処理
  React.useEffect(() => {
    setIsOverlaidLoading(false)
    // 未ログインの時は何もしないで、この関数を抜ける
    if (isLoggedIn === false) {
      return;
    }
    setIsOverlaidLoading(true)
    getUserInfo().then((data) => {
      setUserInfo(data as UserInfo)
    }).catch((e: CmsHttpError) => {
      if (e.statusCode !== 401) {
        // 認証エラー時は、何もしない。
        const message = e.message
        setReloadedDialogState({...reloadedDialogState, isOpen: true, message })
      }
    }).finally(() => {
      setIsOverlaidLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  /** 子階層に提供される値 */
  const providedValues = {
    isLoggedIn, setLoggedIn,
    userInfo, setUserInfo, clearUserInfo,
    isOverlaidLoading, setIsOverlaidLoading,
  };

  return (
    <AppContext.Provider value={providedValues}>
      <OverlaidLoading isOpen={isOverlaidLoading} />
      <ReloadedDialog {...reloadedDialogState} />
      { children }
    </AppContext.Provider>
  )
}


function ReloadedDialog(props: ReloadedDialogProps) {
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={props.isOpen}
        // onCloseをセットすると、背景をクリックでダイアログが閉じるようになる
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"エラーが発生しました"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            { props.message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose}>リロードする</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}