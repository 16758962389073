import { AxiosResponse } from 'axios';
import CmsHttpError from '../../errors/CmsHttpError';
import { createAxios, defaultCatch } from '../axiosUtil'

export interface Response {
    title: string
    info: {
        createdAt: string
        errorLevel: string
        screenshotUri: string
        message: string
    }
}

export default function getTerminalStatusList(organizationId?: number|string): Promise<any> {
    return createAxios().withToken()
        .get<Response[]>(`/logs/terminal-status/list/${organizationId || ''}`)
        .then((response: AxiosResponse<Response[], any>) => {
            return Promise.resolve(response.data);
        }).catch<string>((reason: any) => {
            const error = CmsHttpError.factory(defaultCatch(reason));
            return Promise.reject(error);
        })
}