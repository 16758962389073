import axios, { AxiosInstance as SuperAxiosInstance } from "axios"
import PermanentDataStore from "../supports/PermanentDataStore";
import Config from "../Config"
import isCallable from 'is-callable'

interface Props {
    token?: string|null
}

type ActionList = {[status: number]: () => void};

let defaultActions: ActionList = {}

export function setDefaultAction(status: number, action: () => void) {
    defaultActions[status] = action;
}

export function hasDefaultAction(status: number): boolean {
    return isCallable(defaultActions[status]);
}

export function invokeDefaultAction(status: number): void {
    if (hasDefaultAction(status)) {
        defaultActions[status]()
    } else {
        new Error(`Cannot invoke a '${status}' action.`)
    }
}

/**
 * レスポンスに含まれるバリデーションエラーメッセージを単一文字列に変換する
 * @param data 
 * @returns string
 */
export function validationErrorToString(data: any): string {
    return Object.keys(data?.errors).map(function(key) {
        return data?.errors[key]
    }).join("\n")
}


export function defaultCatch(reason: any, actions: ActionList = {}): [number, string] {
    if (axios.isAxiosError(reason)) {
        if (! reason.response) {
            return [-1, reason.toString()];
        }

        let data: any;
        if ((reason.response.data as any).constructor.name === 'ArrayBuffer') {
            data = JSON.parse(new TextDecoder().decode(
                new Uint8Array((reason.response.data as ArrayBuffer))));
        } else {
            data = reason.response.data ?? {}
        }

        switch(reason.response.status) {

            // 認証処理失敗
            case 401:
                if (isCallable(actions[401])) {
                    actions[401]()
                } else {
                    invokeDefaultAction(401)
                }
                return [401, ''];

            // バリデーションエラー
            case 422:
                return [422, validationErrorToString(data)]

            // Too many requests
            case 429:
                return [429, "リクエスト回数が多すぎます。しばらくしてからもう一度お試しください。"]

            // Unexpected
            default:
                return [reason.response.status, `${reason.response.statusText}(${reason.response.status})`]
        }
    } else {
        // 不明なエラー
        console.error(reason)
        return [-1, reason.toString()]
    }
}


interface SubAxiosInstance extends SuperAxiosInstance {
    /**
     * @param token
     *   token が、`true`と判定される場合はそれを設定すし、`false`と判定された場合は、自動的に既存トークンをセットする
     */
    withToken: (token?: string|null) => SubAxiosInstance;
}

export const createAxios: (props?:Props) => SubAxiosInstance = props => {
    const newAxios: SuperAxiosInstance = axios.create({
        baseURL: Config.baseEndpoint,
        headers: {
            'Accept': 'application/json, application/octet-stream',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${props?.token}`,
        }
    });

    (newAxios as SubAxiosInstance).withToken = (token) => {
        token = token || (PermanentDataStore.load().getToken() ?? '');
        (newAxios.defaults.headers as any)['Authorization'] = `Bearer ${token}`;
        return (newAxios as SubAxiosInstance);
    }

    return (newAxios as SubAxiosInstance);
}