import { makeStyles, createStyles } from '@material-ui/styles';
import ReactDOM from 'react-dom'
import { BounceLoader } from 'react-spinners'
import { Typography, Box } from '@mui/material';

const useStyles = makeStyles(() => createStyles({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
    zIndex: 2000,
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '100vh',
    flexDirection: 'column',
    color: '#333',
  }
}));


const overlaidLoadingDom = document.querySelector('#overlaid-loading') as HTMLDivElement;

interface Props {
  isOpen: boolean
}

export const OverlaidLoading = (props: Props) => {
  const Content = () => {
    const classes = useStyles();
    overlaidLoadingDom.style.zIndex  = '1000'
    overlaidLoadingDom.style.display = props.isOpen ? 'display' : 'none';
    return (
      <div className={classes.overlay}>
        <div className={classes.loading}>
          <BounceLoader loading={true} color={`rgb(54, 215, 183)`} />
          <Box sx={{m:1}} />
          <Typography variant="h4">Please Wait ...</Typography>
        </div>
      </div>
    )
  }

  return (
    props.isOpen 
      ? ReactDOM.createPortal(<Content />, overlaidLoadingDom)
      : ReactDOM.createPortal(null, overlaidLoadingDom)
  );
}

export default OverlaidLoading;