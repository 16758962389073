import PermanentDataStore from "../../supports/PermanentDataStore";
import { AxiosResponse } from "axios"
import { saveAs } from "file-saver"
import { format } from 'date-fns'
import { createAxios, defaultCatch } from '../axiosUtil'

export default function downloadCustomerLog(data: FormData, error: (text: string) => void): Promise<void> {
    const store = PermanentDataStore.load();
    const token = store.getToken()

    const obj: { [key:string]: any } = {};
    data.forEach((v, k) => {obj[k] = v;})

    return createAxios({token}).get<ArrayBuffer>('/logs/seventeen/customer/download', {
        params: obj,
        responseType: 'arraybuffer', // バイナリデータとして扱わないと、サーバで付与されたBOM(あれば)が消える
    }).then((response: AxiosResponse<ArrayBuffer, any>) => {
        // const bytes = new Uint8Array(response.data)
        // console.log(bytes[0].toString(16), bytes[1].toString(16), bytes[2].toString(16))
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        const filename = format(new Date(), 'yyyyMMdd_HHmmss') + '.csv';
        saveAs(blob, filename, {autoBom: false});
    }).catch((reason: any) => {
        const [, m] = defaultCatch(reason);
        error(m);
    })
}